import React from 'react';
import { css } from 'linaria';
import { Hero, Main } from 'mediasoil-shared-components/components/';
import cls from 'mediasoil-shared-components/utils/class_strings';

export default props => {
  return (
    <Main
      {...props}
      className={cls(
        props.className,
        css`
          flex: 1;
        `
      )}
      components={{
        Hero,
      }}
    />
  );
};
