import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from 'mediasoil-shared-components/gatsby/components/seo';
import LogoImage from 'Img/logo.png';

export default ({ link, image, ...props }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const linksWithGoogleFont = (link || []).concat([
    {
      rel: 'stylesheet',
      href:
        'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap&subset=latin',
    },
    {
      rel: 'stylesheet',
      href:
        'https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@400;700&display=swap&text=0123456789DAYSUNTILOPEG',
    },
  ]);

  return <SEO siteMetadata={siteMetadata} image={image || { url: LogoImage }} link={linksWithGoogleFont} {...props} />;
};
