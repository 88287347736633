import React, { useContext } from 'react';
import { css } from 'linaria';
import { Action } from 'mediasoil-shared-components/components/';
import cls from 'mediasoil-shared-components/utils/class_strings';
import { StoreContext } from '../store';
import { categories } from '../../../content/productData.json';
import Logo from 'Img/logo.svg';
import EmailIcon from 'Img/email.svg';
import PhoneIcon from 'Img/phone.svg';
import CartIcon from 'Img/cart.svg';

const styles = {
  header: css`
    display: flex;
    flex-direction: column;
    margin-bottom: helpers.$gutter;

    @include helpers.mq(header) {
      display: grid;
      grid-template-columns: 15em 1fr;
      grid-template-rows: 1fr auto auto;
    }
  `,
  logo: css`
    position: relative;

    @include helpers.mq(header, true) {
      padding-top: helpers.$gutter;

      &::before {
        @include helpers.bleed();
        background: #292f7e;
        content: '';
      }
    }

    @include helpers.mq(header) {
      grid-column: 1;
      grid-row: 1 / 4;
      margin: helpers.$gutter 0;
    }

    svg {
      background: white;
      display: block;
      border: 2px solid #292f7e;
      padding: helpers.$gutter/2;
      width: 100%;
    }
  `,
  nav: css`
    align-items: center;
    background: #292f7e;
    color: white;
    display: flex;
    grid-column: 2;
    padding: 0 helpers.$gutter;
    position: relative;

    &::before {
      @include helpers.bleed();
      content: '';
      width: calc(100% + 100vw);
    }

    a {
      padding: helpers.$gutter/4 helpers.$gutter;

      &:hover {
        background: rgba(black, 0.1);
      }
    }
  `,
  infonav: css`
    align-items: flex-end;
    font-size: 0.8em;
    padding: helpers.$gutter / 4 0;

    @include helpers.mq(header) {
      padding: helpers.$gutter/2 helpers.$gutter/2 helpers.$gutter/2 0;
    }

    @include helpers.mq(header-info, true) {
      [title='Phone'] ~ span {
        font-size: 0;
      }
    }
  `,
  contentnav: css`
    background: white;
    color: black;
    padding: helpers.$gutter / 4 0;

    @include helpers.mq(header) {
      padding: 0;
    }
  `,
  productsnav: css`
    background: none;
    padding: helpers.$gutter / 4 0;

    @include helpers.mq(header) {
      padding: 0 0 helpers.$gutter/4;
    }

    &::before {
      background: #292f7e;
      background-size: cover;
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      height: 1.75em;
      overflow: hidden;

      @include helpers.mq(header) {
        height: 2em;
        margin-bottom: helpers.$gutter/1.5;
      }
    }

    a {
      flex-shrink: 0;

      @include helpers.mq(header, true) {
        @include helpers.sr-only();
      }

      @include helpers.mq(header) {
        margin-top: helpers.$gutter/4;
      }
    }
  `,
  mobileselect: css`
    align-items: center;
    display: flex;
    cursor: pointer;
    flex-shrink: 0;
    padding: helpers.$gutter/4 helpers.$gutter;
    position: relative;

    @include helpers.mq(header) {
      margin-top: helpers.$gutter/4;
    }

    &:hover {
      background: rgba(black, 0.1);
    }

    &::after {
      content: 'Products';
    }

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 32 32'%3E%3Cpath d='M2 6h28v4H2V6zM2 14h28v4H2v-4zM2 22h28v4H2v-4z'/%3E%3C/svg%3E");
      content: '';
      display: inline-block;
      height: 1em;
      margin: -2.5% helpers.$gutter / 2 0 0;
      width: 1em;
    }

    select {
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  `,
  svg: css`
    display: inline-block;
    margin: -0.1em 0.5em 0 0;
    height: 1em;
    vertical-align: middle;
    width: 1em;
  `,
  cartlink: css`
    span {
      line-height: 0;
      vertical-align: middle;
    }
  `,
};

const Header = ({ title }) => {
  const numInCart = Object.entries(useContext(StoreContext)[0]).reduce(
    (acc, [_, { quantity }]) => acc + (+quantity || 0),
    0
  );

  return (
    <header className={styles.header}>
      <Action href="/" className={styles.logo}>
        <svg aria-label={title} title={title} viewBox="0 0 663.975 288.681">
          <use xlinkHref={`#${Logo.id}`} />
        </svg>
      </Action>
      <nav className={cls(styles.nav, styles.infonav)}>
        <Action href="tel:1-402-230-9180">
          <svg aria-label="Phone" title="Phone" className={styles.svg}>
            <use xlinkHref={`#${PhoneIcon.id}`} />
          </svg>
          <span>402-230-9180</span>
        </Action>

        <Action href="mailto:cncfireworksshows@gmail.com">
          <svg aria-label="Email" title="Email" className={styles.svg}>
            <use xlinkHref={`#${EmailIcon.id}`} />
          </svg>
          cncfireworksshows@gmail.com
        </Action>

        {/* <Action className={styles.cartlink} href="/cart">
          <svg aria-label="Cart" title="Cart" className={styles.svg}>
            <use xlinkHref={`#${CartIcon.id}`} />
          </svg>
          <span>{numInCart}</span>
        </Action> */}
      </nav>
      <nav className={cls(styles.nav, styles.contentnav)}>
        <Action href="/">Home</Action>
        <Action href="/about">About Us</Action>
        <Action href="/contact">Contact Us</Action>
      </nav>
      <nav className={cls(styles.nav, styles.productsnav)}>
        <div>
          <div aria-hidden="true" className={styles.mobileselect}>
            <select onChange={({ target }) => (window.location = target.value)}>
              <option selected disabled></option>
              <option value="/products?">All Products</option>
              {categories.map(({ id, name }) => (
                <option key={id} value={`/products?category=${id}`}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          {categories.map(({ id, name }) => (
            <Action key={id} href={`/products?category=${id}`}>
              {name}
            </Action>
          ))}
        </div>
      </nav>
    </header>
  );
};

export default Header;
