import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'linaria';
// import { differenceInHours, parse } from 'date-fns';
import { differenceInHours } from 'date-fns';
import { Action, Endorse } from 'mediasoil-shared-components/components/';
// import { processHours, activeLocations } from '../locations/locations';
import { products } from '../../../content/productData.json';

// Get Product Gallery Images
const productsLn = products.length;
const chosenImages = new Map();
while (chosenImages.size < 12) {
  const num = Math.floor(Math.random() * productsLn);
  const { id, image, name } = products[num];

  if (image && id) {
    chosenImages.set(id, [image, name]);
  }
}
const chosenImagesArray = Array.from(chosenImages);
//

const styles = {
  footer: css`
    $imageHeight: helpers.$gutter * 1.5;

    color: white;
    margin-top: helpers.$gutter;
    padding: $imageHeight helpers.$gutter 0;
    position: relative;

    &::before {
      @include helpers.bleed();

      background: #292f7e;
      content: '';
    }

    a {
      color: currentColor;

      &:hover {
        text-decoration: underline;
      }
    }
  `,
  footerLists: css`
    display: inline-block;
    left: 50%;
    position: relative;
    transform: translateX(-50%);

    @include helpers.mq(footer-1) {
      display: grid;
      grid-gap: helpers.$gutter * 1.5;
      grid-column-gap: helpers.$gutter * 2;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto;
      left: 0;
      margin: helpers.$gutter * 2 0;
      transform: none;

      > * {
        grid-row: 2;

        &:nth-child(n + 5) {
          grid-row: 4;
        }
      }

      > h5 {
        grid-row: 1;
        margin: 0;

        &:nth-of-type(n + 3) {
          grid-row: 3;
        }
      }
    }

    @include helpers.mq(footer-2) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto auto;

      > *:nth-child(n + 1) {
        grid-row: 2;
      }

      > h5:nth-child(n + 1) {
        grid-row: 1;
      }
    }

    h5:first-child {
      @include helpers.mq(footer-1, true) {
        margin-top: helpers.$gutter * 1.5;
      }
    }
  `,
  footerListsGetInTouch: css`
    display: table;
    padding: 0;

    @include helpers.mq(footer-2) {
      padding: 0 0 helpers.$gutter;
    }

    div {
      display: table-row;
    }

    strong {
      display: table-cell;
      vertical-align: middle;
    }

    p {
      display: table-cell;
      padding: helpers.$gutter / 2;
      vertical-align: middle;
      width: 100%;
    }
  `,
  navigation: css`
    list-style: none;
    margin: 0;
    padding: 0;

    @include helpers.mq(footer-2) {
      padding: 0 0 helpers.$gutter;
    }

    li {
      padding: helpers.$gutter / 2 0;

      &:first-child {
        padding-top: 0;
      }
    }
  `,
  gallery: css`
    display: grid;
    grid-gap: 1px;
    grid-template-columns: repeat(4, 60px);
    grid-template-rows: repeat(3, 60px);
    list-style: none;
    margin: 0;
    padding: 0;

    @include helpers.mq(footer-2) {
      padding: 0 0 helpers.$gutter;
    }

    li {
      overflow: hidden;
    }

    a {
      display: block;
      position: relative;

      &::after {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: background 0.115s ease-in-out;
        width: 100%;
      }

      &:hover::after {
        background: rgba(#d90000, 0.5);
      }
    }
  `,
  daysUntil: css`
    font-family: 'Frank Ruhl Libre', serif;
    line-height: 1;
    margin: helpers.$gutter * 2 0 helpers.$gutter;
    padding: 0 0 helpers.$gutter;

    @include helpers.mq(footer-1) {
      grid-row: 4 / 6;
      margin: 0;
    }

    @include helpers.mq(footer-2) {
      align-self: center;
      grid-row: 1 / 3;
    }

    [aria-hidden='true'] {
      display: flex;
      justify-content: center;
    }

    svg {
      fill: #d90000;
      height: 1em;
      margin: 0 0.5em;
      width: 1em;
    }

    strong {
      color: white;
      display: block;
      font-size: 12.4rem;
      font-weight: 700;
    }

    h5 {
      color: #999;
      display: inline-block;
      font-size: 1.7rem;
      font-weight: 400;
      letter-spacing: 0.2rem;
      margin: 0;
      text-transform: uppercase;
      white-space: nowrap;

      @include helpers.mq(footer-2) {
        display: block;
      }
    }
  `,
  daysUntilCnt: css`
    text-align: center;

    @include helpers.mq(footer-1) {
      display: inline-block;
    }

    @include helpers.mq(footer-2) {
      display: block;
    }
  `,
  endorse: css`
    background: rgba(black, 0.15);
    border-radius: 3px 3px 0 0;
    font-size: 0.85em;
    margin: auto;
    max-width: 50em;
    padding: helpers.$gutter / 1.5;
    position: relative;
    text-align: center;

    a:hover {
      text-decoration: none;
    }
  `,
  social: css`
    a {
      display: inline-block;
      margin-left: helpers.$gutter/2;

      &:first-child {
        margin-left: 0;
      }
    }
  `,
  fb: css`
    height: 1.75em;
    width: 1.75em;
  `,
};

const Footer = ({ title }) => {
  const [tillOpening, setTillOpening] = useState(null);

  useEffect(() => {
    const date = new Date();
    const currentYear = date.getFullYear();
    // const pfmt = 'MMMM do yyyy h:mm a';

    // const earliestLocationDate = activeLocations
    //   .map(({ business_hours }) =>
    //     processHours(business_hours?.periods)[0]
    //       .slice(0, 2)
    //       .join('%%%')
    //       .replace('%%%', ` ${currentYear} `)
    //   )
    //   .reduce((acc, cur) => {
    //     const time = parse(cur, pfmt, new Date());

    //     if (!acc) {
    //       return time;
    //     }

    //     return time < acc ? time : acc;
    //   });
    // const earliestLocationDateAsDate = parse(earliestLocationDate, pfmt, new Date());
    // let diff = Math.ceil(differenceInHours(earliestLocationDateAsDate, date) / 24);

    let diff = Math.ceil(differenceInHours(new Date(currentYear, 5, 28, 9), date) / 24); // June 28th, 9am

    if (diff < 0) {
      diff = 0;
    }

    setTillOpening(diff);
  }, []);

  return (
    <footer className={styles.footer}>
      <div className={styles.footerLists}>
        <h5>Get in Touch</h5>
        <div className={styles.footerListsGetInTouch}>
          <div>
            <strong>Phone:</strong>
            <p>
              <Action href="tel:1-402-230-9180">402-230-9180</Action>
            </p>
          </div>
          <div>
            <strong>Email:</strong>
            <p>
              <Action href="mailto:cncfireworksshows@gmail.com">cncfireworksshows@gmail.com</Action>
            </p>
          </div>
          <div>
            <strong>Social:</strong>
            <p className={styles.social}>
              <Action href="https://www.facebook.com/CNCFIREWORKSne" title="Facebook" target="blank">
                <svg className={styles.fb} viewBox="0 0 32 32">
                  <title>Facebook</title>
                  <path d="M31.997 15.999C31.997 7.163 24.834 0 15.998 0S-.001 7.163-.001 15.999c0 7.985 5.851 14.604 13.499 15.804v-11.18H9.436v-4.625h4.062v-3.525c0-4.01 2.389-6.225 6.043-6.225 1.75 0 3.581.313 3.581.313v3.937h-2.017c-1.987 0-2.607 1.233-2.607 2.498v3.001h4.437l-.709 4.625h-3.728v11.18c7.649-1.2 13.499-7.819 13.499-15.804z" />
                </svg>
              </Action>
              {/* <Action href="https://twitter.com/cncfireworks" title="Twitter" target="blank">
                <svg className={styles.fb} viewBox="0 0 32 32">
                  <title>Twitter</title>
                  <path d="M32 7.075c-1.175 0.525-2.444 0.875-3.769 1.031 1.356-0.813 2.394-2.1 2.887-3.631-1.269 0.75-2.675 1.3-4.169 1.594-1.2-1.275-2.906-2.069-4.794-2.069-3.625 0-6.563 2.938-6.563 6.563 0 0.512 0.056 1.012 0.169 1.494-5.456-0.275-10.294-2.888-13.531-6.862-0.563 0.969-0.887 2.1-0.887 3.3 0 2.275 1.156 4.287 2.919 5.463-1.075-0.031-2.087-0.331-2.975-0.819 0 0.025 0 0.056 0 0.081 0 3.181 2.263 5.838 5.269 6.437-0.55 0.15-1.131 0.231-1.731 0.231-0.425 0-0.831-0.044-1.237-0.119 0.838 2.606 3.263 4.506 6.131 4.563-2.25 1.762-5.075 2.813-8.156 2.813-0.531 0-1.050-0.031-1.569-0.094 2.913 1.869 6.362 2.95 10.069 2.95 12.075 0 18.681-10.006 18.681-18.681 0-0.287-0.006-0.569-0.019-0.85 1.281-0.919 2.394-2.075 3.275-3.394z"></path>
                </svg>
              </Action>
              <Action href="https://www.instagram.com/cncfireworksbeatrice/" title="Instagram" target="blank">
                <svg className={styles.fb} viewBox="0 0 32 32">
                  <title>Instagram</title>
                  <path d="M16 2.881c4.275 0 4.781 0.019 6.462 0.094 1.563 0.069 2.406 0.331 2.969 0.55 0.744 0.288 1.281 0.638 1.837 1.194 0.563 0.563 0.906 1.094 1.2 1.838 0.219 0.563 0.481 1.412 0.55 2.969 0.075 1.688 0.094 2.194 0.094 6.463s-0.019 4.781-0.094 6.463c-0.069 1.563-0.331 2.406-0.55 2.969-0.288 0.744-0.637 1.281-1.194 1.837-0.563 0.563-1.094 0.906-1.837 1.2-0.563 0.219-1.413 0.481-2.969 0.55-1.688 0.075-2.194 0.094-6.463 0.094s-4.781-0.019-6.463-0.094c-1.563-0.069-2.406-0.331-2.969-0.55-0.744-0.288-1.281-0.637-1.838-1.194-0.563-0.563-0.906-1.094-1.2-1.837-0.219-0.563-0.481-1.413-0.55-2.969-0.075-1.688-0.094-2.194-0.094-6.463s0.019-4.781 0.094-6.463c0.069-1.563 0.331-2.406 0.55-2.969 0.288-0.744 0.638-1.281 1.194-1.838 0.563-0.563 1.094-0.906 1.838-1.2 0.563-0.219 1.412-0.481 2.969-0.55 1.681-0.075 2.188-0.094 6.463-0.094zM16 0c-4.344 0-4.887 0.019-6.594 0.094-1.7 0.075-2.869 0.35-3.881 0.744-1.056 0.412-1.95 0.956-2.837 1.85-0.894 0.888-1.438 1.781-1.85 2.831-0.394 1.019-0.669 2.181-0.744 3.881-0.075 1.713-0.094 2.256-0.094 6.6s0.019 4.887 0.094 6.594c0.075 1.7 0.35 2.869 0.744 3.881 0.413 1.056 0.956 1.95 1.85 2.837 0.887 0.887 1.781 1.438 2.831 1.844 1.019 0.394 2.181 0.669 3.881 0.744 1.706 0.075 2.25 0.094 6.594 0.094s4.888-0.019 6.594-0.094c1.7-0.075 2.869-0.35 3.881-0.744 1.050-0.406 1.944-0.956 2.831-1.844s1.438-1.781 1.844-2.831c0.394-1.019 0.669-2.181 0.744-3.881 0.075-1.706 0.094-2.25 0.094-6.594s-0.019-4.887-0.094-6.594c-0.075-1.7-0.35-2.869-0.744-3.881-0.394-1.063-0.938-1.956-1.831-2.844-0.887-0.887-1.781-1.438-2.831-1.844-1.019-0.394-2.181-0.669-3.881-0.744-1.712-0.081-2.256-0.1-6.6-0.1v0z"></path>
                  <path d="M16 7.781c-4.537 0-8.219 3.681-8.219 8.219s3.681 8.219 8.219 8.219 8.219-3.681 8.219-8.219c0-4.537-3.681-8.219-8.219-8.219zM16 21.331c-2.944 0-5.331-2.387-5.331-5.331s2.387-5.331 5.331-5.331c2.944 0 5.331 2.387 5.331 5.331s-2.387 5.331-5.331 5.331z"></path>
                  <path d="M26.462 7.456c0 1.060-0.859 1.919-1.919 1.919s-1.919-0.859-1.919-1.919c0-1.060 0.859-1.919 1.919-1.919s1.919 0.859 1.919 1.919z"></path>
                </svg>
              </Action> */}
            </p>
          </div>
        </div>

        <h5>Navigation</h5>
        <ul className={styles.navigation}>
          <li>
            <Action href="/">Home</Action>
          </li>
          <li>
            <Action href="/about">About Us</Action>
          </li>
          <li>
            <Action href="/products">Products</Action>
          </li>
          <li>
            <Action href="/contact">Contact Us</Action>
          </li>
        </ul>

        <h5>Random Product Gallery</h5>
        <ul className={styles.gallery}>
          {chosenImagesArray.map(([id, [image, name]]) => (
            <li key={id}>
              <Action href={`/products/${id}`}>
                <img
                  alt={name}
                  height="60"
                  loading="lazy"
                  src={`https://images-production-f.squarecdn.com/unsafe/60x60/${image}`}
                  srcSet={`https://images-production-f.squarecdn.com/unsafe/120x120/${image} 2x`}
                  width="60"
                />
              </Action>
            </li>
          ))}
        </ul>

        {tillOpening != null && (
          <div className={styles.daysUntil}>
            <div className={styles.daysUntilCnt}>
              <div aria-hidden="true">
                {[0, 0, 0].map((_, i) => (
                  <svg key={i} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                  </svg>
                ))}
              </div>
              <h5>
                <strong>{tillOpening}</strong> Days Until Opening
              </h5>
            </div>
          </div>
        )}
      </div>
      <div className={styles.endorse}>
        <Endorse siteTitle={title} />
      </div>
    </footer>
  );
};

Footer.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Footer;
