import React, { createContext, useState } from 'react';
import { window } from 'browser-monads';

export const LOCAL_STORE_NAME = 'curbside';

export const getStoreKey = fields => `${fields.product}-${fields.variation}`;
export const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  let defaultState = {};

  try {
    defaultState = JSON.parse(window.localStorage.getItem(LOCAL_STORE_NAME)) || defaultState;
  } catch (e) {}

  const [products, setProductQuantities] = useState(defaultState);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const updateLocalStore = updateProducts => {
    window.localStorage.setItem(LOCAL_STORE_NAME, JSON.stringify(updateProducts));
    setProductQuantities(updateProducts);
    setLastUpdated(Date.now());
  };

  const methods = {
    setProduct({ target }) {
      const { product, variation, quantity } = ['product', 'variation', 'quantity'].reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: target?.[cur]?.value,
        }),
        {}
      );

      products[getStoreKey({ product, variation })] = {
        product,
        variation,
        quantity: +quantity || 0,
      };

      updateLocalStore(products);
    },
  };

  return <StoreContext.Provider value={[products, methods, lastUpdated]}>{children}</StoreContext.Provider>;
};

export default StoreProvider;
